import { UnstyledLink } from "./UnstyledLink"

interface LearnMoreCTAProps {
    href?: string,
    text?: string,
    onClick?: () => void,
}

export const LearnMoreCTA = ({ href = "#", text = "Learn more", onClick }: LearnMoreCTAProps) => {
    return <UnstyledLink href={!onClick ? href : undefined} target="_blank" textDecoration="underline" onClick={onClick}>{text}</UnstyledLink>
}